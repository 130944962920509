<template>
  <b-card v-if="threedates" no-body class="card-developer-meetup">
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <b-row>
        <b-col cols="md-12" v-for="(dt, index) in threedates" :key="index">
          <div class="meetup-header d-flex align-items-center">
            <div class="meetup-day" style="width: 150px;">
              <h4 class="mb-0">
                {{ dt.type }}
              </h4>
            </div>
            <div class="my-auto">
              <router-link class="link-title" :to="{ name: dt.link , params: { id : dt.data } }">
                <b-card-title class="mb-25">
                  <h6>
                    {{ dt.title }}
                  </h6>
                </b-card-title>
              </router-link>
              <b-card-text class="mb-0">
                <p>{{ dt.day }}</p>
              </b-card-text>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
} from "bootstrap-vue";
import axios from "axios";
import moment from 'moment';

export default {
  components: {
    BBadge,
    BCol,
    BRow,
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      threedates: [],
    };
  },
  methods: {
  },
  computed: {},
  created() {
    axios
    .get(
      "https://backendapinodejs.timecheckit.com/api/date?user=" + JSON.parse(localStorage.getItem("userData"))._id
    )
    .then((response) => {
      const dates = response.data.dates.slice(-3)
      dates.forEach(element => {
        const start = moment.utc(element.startDate).format('YYYY-MM-DD HH:mm')
        const end = moment.utc(element.endDate).format('YYYY-MM-DD HH:mm')
        var data = null
        var type = ""
        var link = ""
        if (element.type==="event") {
          type = "Event"
          link = "apps-calendar"
        }
        else if (element.type==="subObjectif") {
          type = "Objectif"
          link = "apps-objectifs"
        }
        else if (element.type==="meetingInvitation") {
          data = element.meeting
          type = "Meeting"
          link = "apps-meetings-general"
        }
        else if (element.type==="todo") {
          link = "apps-todo"
          type = "To Do"
        }
        else {
          type = element.type
        }
        this.threedates.push({
          data : data,
          title : element.title ? element.title : "",
          type : type,
          day : `${start} - ${end}`,
          link : link
        })
      });
    })
      .catch((error) => {
        console.error("There was an error!", error);
    });
  },
};
</script>

<style scoped>
.link-title:hover {
  text-decoration: underline;
}
</style>
