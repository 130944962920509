<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <!-- <b-col lg="12" md="12">
        <analytics-congratulation :data="data.congratulations" />
      </b-col> -->
    <div class="col-md-6 col-12">
      <echart-line  
      v-if="dataLoaded"
      :statistic-title="$t('Todo')"
      :count="todos"
      :type="'todo'"
      />
    </div>
    <div class="col-md-6 col-12">
      <echart-line 
      v-if="dataLoaded"
      :count="projects"
      :statistic-title="$t('Projects')"
      :type="'project'"
      />
     </div>
    <div class="col-md-6 col-12">
      <echart-line 
      v-if="dataLoaded"
      :count="meetings"
       :statistic-title="$t('Meetings')"
      :type="'meetingInvitation'"
    />
    </div>
    <div class="col-md-6 col-12">
      <echart-line 
      v-if="dataLoaded"
      :count="objectifs"
      :statistic-title="$t('Objectives')"
      :type="'subObjectif'"
      />
      </div>
    </b-row>

    <b-row class="match-height">
      <!-- <b-col lg="6">
        <analytics-avg-sessions :data="data.avgSessions" />
      </b-col> -->
      <b-col lg="6">
        <analytics-support-tracker />
      </b-col>
      <!-- Developer Meetup Card -->
      <b-col lg="6">
        <ecommerce-meetup />
      </b-col>
      <!--/ Developer Meetup Card -->
    </b-row>

    <!-- <b-row class="match-height">
      <b-col lg="4">
        <analytics-timeline :data="data.timeline" />
      </b-col>
      <b-col lg="4">
        <analytics-sales-radar-chart :data="data.salesChart" />
      </b-col>
      <b-col lg="4">
        <analytics-app-design :data="data.appDesign" />
      </b-col>
    </b-row> -->
    <!-- 
    <b-row>
      <b-col cols="12">
        <invoice-list />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import store from "@/store";
import EchartLine from './EchartLine.vue'
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import InvoiceList from "@/views/apps/invoice/invoice-list/InvoiceList.vue";
import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import AnalyticsAvgSessions from "./AnalyticsAvgSessions.vue";
import AnalyticsSupportTracker from "./AnalyticsSupportTracker.vue";
import AnalyticsTimeline from "./AnalyticsTimeline.vue";
import AnalyticsSalesRadarChart from "./AnalyticsSalesRadarChart.vue";
import AnalyticsAppDesign from "./AnalyticsAppDesign.vue";
import EcommerceMeetup from "./../ecommerce/EcommerceMeetup.vue";
import axios from "axios";
import moment from 'moment';

export default {
  components: {
    EchartLine,
    BRow,
    BCol,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    InvoiceList,
    EcommerceMeetup,
  },
  data() {
    return {
      meetings : "0",
      objectifs : "0",
      projects : "0",
      todos : "0",
      dataLoaded: false,
    };
  },
  created() {
    this.getCount()
  },
  methods: {
    kFormatter,
    getCount() {
      axios
      .get(
        "https://backendapinodejs.timecheckit.com/api/dashboard?user=" + JSON.parse(localStorage.getItem("userData"))._id
      )
      .then((response) => {
        this.meetings = response.data.data.meetings.toString();
        this.projects = response.data.data.projects.toString();
        this.todos = response.data.data.todos.toString();
        this.objectifs = response.data.data.objectifs.toString();
        this.dataLoaded = true;
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    },
  },
};
</script>
<style scoped>
.videoicon .b-avatar.badge-light-primary {
    color: #ff0000;
}
</style>
