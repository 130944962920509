<template>
  <b-card :title="statisticTitle">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-1 mb-sm-0"></div>
      <div class="d-flex align-content-center mb-1 mb-sm-0">
        <h1 class="font-weight-bolder">
          {{ count }}
        </h1>
      </div>
    </div>
    <!-- echart -->
    <app-echart-line v-if="dataLoaded" :option-data="option" />
  </b-card>
</template>

<script>
import { BCard, BBadge } from "bootstrap-vue";
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    BCard,
    AppEchartLine,
    BBadge,
  },
  props: {
    count: {
      type: String,
      default: "0",
    },
    type: {
      type: String,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dataLoaded: false,
      option: {
        xAxisData: [],
        series: [],
      },
    };
  },
  async created() {
    const today = moment.utc();
    const endOfWeek = today.format("YYYY-MM-DD");
    const startOfWeek = today
      .subtract(1, "week")
      .startOf("week")
      .format("YYYY-MM-DD");
    const start = new Date(startOfWeek);
    const end = new Date(endOfWeek);
    const dates = [];
    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      dates.push(new Date(date).toISOString().split("T")[0]);
      this.option.xAxisData.push(moment(date).format("DD/MM"));
    }
    const datesData = [];
    await this.getMettings(datesData);
    await this.getProject(datesData);
    await this.getObj(datesData);
    await this.getAllDates(datesData);

    setTimeout(() => {
      this.option.series = this.calculCount(dates, datesData);
      this.dataLoaded = true;
    }, 500);
  },
  methods: {
    getAllDates(datesData) {
      if (this.type === "todo") {
        axios
          .get(
            "https://backendapinodejs.timecheckit.com/api/todos?user=" +
              JSON.parse(localStorage.getItem("userData"))._id
          )
          .then((response) => {
            response.data.data.forEach((element) => {
              datesData.push({
                start: moment(element.createdAt).format("YYYY-MM-DD"),
                end: moment(element.createdAt).format("YYYY-MM-DD"),
              });
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
    },
    getObj(datesData) {
      if (this.type == "subObjectif") {
        axios
          .get(
            "https://backendapinodejs.timecheckit.com/api/objectifs?user=" +
              JSON.parse(localStorage.getItem("userData"))._id
          )
          .then((response) => {
            response.data.data.forEach((element) => {
              datesData.push({
                start: moment(element.createdAt).format("YYYY-MM-DD"),
                end: moment(element.createdAt).format("YYYY-MM-DD"),
              });
            });
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
    },
    getProject(datesData) {
      if (this.type == "project") {
        axios
          .get(
            "https://backendapinodejs.timecheckit.com/api/projects?user=" +
              JSON.parse(localStorage.getItem("userData"))._id
          )
          .then((response) => {
            response.data.data.forEach((element) => {
              datesData.push({
                start: moment(element.createdAt).format("YYYY-MM-DD"),
                end: moment(element.createdAt).format("YYYY-MM-DD"),
              });
            });
          });
      }
    },
    getMettings(datesData) {
      if (this.type == "meetingInvitation") {
        axios
          .get(
            "https://backendapinodejs.timecheckit.com/api/meetings?user=" +
              JSON.parse(localStorage.getItem("userData"))._id
          )
          .then((response) => {
            this.count = response.data.count.toString();
            response.data.data.forEach((element) => {
              datesData.push({
                start: moment(element.startDate).format("YYYY-MM-DD"),
                end: moment(element.endDate).format("YYYY-MM-DD"),
              });
            });
          });
      }
    },
    calculCount(dates, datesData) {
      const series = [];
      dates.forEach((dateOp, index) => {
        let count = 0;
        const day = moment(dateOp, "dddd YYYY-MM-DD");
        datesData.forEach((date) => {
          const startDate = moment.utc(date.start).format("YYYY-MM-DD");
          const endDate = moment.utc(date.end).format("YYYY-MM-DD");
          if (day.isBetween(startDate, endDate, null, "[]")) {
            count++;
          }
        });
        series.push(String(count));
      });
      return series;
    },
  },
};
</script>
<style>
.echarts {
  height: 200px !important;
}
</style>